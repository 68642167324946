.App {
  text-align: center;
}
:root {
  --main-color: #5ea3d0;
  --secondary-color: white;
  --main-text-color: #3e5869;
  --secondary-text-color: #b0c7d6;
  --send-message-form: #f5f5f5;
}
.app {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 40px 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "b b b b b b"
    "c c c c c c"
    "c c c c c c"
    "c c c c c c"
    "v v v v v v"
    "v v v v v v"
    "v v v v v v";
  overflow: hidden;
}

.vidyo {
  grid-area: v;
  display: grid;
}

.wrapper {
  grid-area: c;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 60px;
  grid-template-areas:
    "m m m m m m m"
    "m m m m m m m"
    "m m m m m m m"
    "m m m m m m m"
    "m m m m m m m"
    "f f f f f f n";
}



.new-room-form {
  grid-area: n;
}
.rooms-list {
  grid-area: r;
  box-sizing: border-box;
  padding: 10px;
  
  background: linear-gradient(to right, white ,var(--main-color) 80%);
  overflow: scroll;
  height: 100%;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.rooms-list::-webkit-scrollbar {
  display: none; /* Hide the scrollbar for WebKit based browsers */
}
.rooms-list ul {
  list-style-type: none;
  padding: 0;
}
.rooms-list li {
  margin: 10px 0;
}

.rooms-list h3 {
  margin: 5px 0;
  color: var(--secondary-color);
}

.rooms-list .room a {
  color: var(--secondary-text-color);
  font-weight: 600;
  text-decoration: none;
  font-size: 20px;
}

.rooms-list .room.active a {
  color: var(--secondary-color);
}

.message-list {
  grid-area: m;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}

.message-list::-webkit-scrollbar {
  display: none; /* Hide the scrollbar for WebKit based browsers */
}
.send-message-form {
  grid-area: f;
  width: 100%;
}
.send-message-form input {
  grid-area: f;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  font-size: 15px;
  border-radius: 20px;
  margin-left: 1%;

}
.send-message-form input:focus {
  outline: none; /* Remove the default focus outline */
  border: none; /* Remove the border */

  /* Additional styling if desired */
}

.new-room-form {
  /* background: linear-gradient(to right, white ,var(--main-color) 80%); */
  color: var(--main-text-color);
  height: 100%;

}

.new-room-form form {
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.new-room-form form input {
  grid-area: n;
  width: -webkit-fill-available;
  height: 100%;
  width: 100%;
  font-size: 15px;
  
  /* border-bottom: none; */
  
}
.new-room-form form input:focus {
 outline: none;
}

#create-room-btn {
  color: #69ffb4;
  background: gainsboro;
  height: 100%;
  border: 2px double;
  outline: none;
  -webkit-filter: blur();
  -moz-filter: blur();
  -o-filter: blur();
  -ms-filter: blur();
  filter: blur();
}

#create-room-btn:hover {
  cursor: pointer;
  text-shadow: 1px 1px white;
}
.join-a-room {
 
  margin-left: 5%;
  font-size: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.message-text {
  padding: 2px;
  background-color: var(--main-color);
  width: fit-content;
  color: white;
  display: -webkit-inline-box;
  font-size: 10px;
  border-radius: 5px 5px 0 5px;
}
.others-message-text {
  padding: 2px;
  background-color: #cccccc;
  width: fit-content;
  color: black;
  display: -webkit-inline-box;
  font-size: 10px;
  border-radius: 5px 5px 5px 0;
}
.message-username {
  font-size: 7px;
  margin-left: 5%;
  padding: 1%;
  color: #5ea3d0;
  font-weight: 800;
  display: -webkit-inline-box;
}
.message-username:hover {
  cursor: pointer;
  text-shadow: 2px 2px #6bccf9;
}

button.rs-play {
  background-color: "red";
}
