nav {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
}

.background {
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  width: 300px;
  z-index: -100;
  height: 400px;
  background: white;
  border-radius: 0% 0% 0% 5%;
  box-shadow: 2px 6px 9px 0px grey;
}

.navButton {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  z-index: 101;
}

.navLi {
  margin: 0;
  padding: 0;
}

.navUl {
  padding: 25px;
  position: relative;
  top: 30px;
  width: 80%;
}

.navLi {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
