.mapboxgl-popup-close-button {
    font-size: 25px;
    margin: 2px;
    width: 28px !important;
    height: 28px;
    display: flex;
    align-items: center;
    color: black
   
}

.mapboxgl-popup-content {
    border-radius: 8px;
    padding-top: 0px;

}

